import React from 'react';
import SEO from "../components/seo"
import { Footer, Header, Page } from '@components';
import NotFound from '@sections/NotFound'

const NotFoundPage = () => (
  <Page>
    <SEO
      title="Page not found"
    />
    <Header />
    <NotFound />
    <Footer />
  </Page>
)

export default NotFoundPage
