import React from 'react';
import { Link } from 'gatsby';
import Img from '@images/404.svg';
import './styles.scss';

const NotFound = () => {
  return (
    <div className="NotFound">
      <div>
        <span>
          404
        </span>
        <h1>
          Page not found
        </h1>
        <p>
          There is nothing here, please go to home <Link to="/">home</Link>
        </p>
      </div>
      <img src={Img} alt="Page not found"/>
    </div>
  );
}

export default NotFound;